.container-fluid {
  max-width: 1380px;
  padding-left: $container-fluid-padding;
  padding-right: $container-fluid-padding;

  @media screen and (max-width: $lg) {
    //max-width: 768px;
    padding-left: $container-fluid-padding-md;
    padding-right: $container-fluid-padding-md;
  }

  @media screen and (max-width: $sm) {
    padding-left: $container-fluid-padding-mobile;
    padding-right: $container-fluid-padding-mobile;
  }
}

.section-heading {
  margin-bottom: 1.2em;
  font-size: 35px;

  @media screen and (max-width: $md) {
    font-size: 30px;
  }

  @media screen and (max-width: $xs) {
    font-size: 27px;
  }
}

.underscore {
  &:after {
    margin-top: 0.3em;
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    border-radius: 10px;
    background: $brand;
  }
}

.helper {
  font-weight: 300;
  line-height: 1.2;
  //font-style: italic;
  color: $brand-secondary;
}

.label {
  font-size: 14px;
  @extend .uppercase;
  @extend .tracked-less;
  @extend .bold;
  color: $medium-dark;
  @extend .font-main;

  @media screen and (max-width: $sm) {
    font-size: 12px;
  }
}

.outlined {
  border: 1px solid $tan-dark;
  border-radius: 6px;
}

.boxed {
  @extend .outlined;
  @extend .shadowed;
  padding: 20px;
  background: white;

  @media screen and (max-width: $sm) {
    padding: 10px;
  }
}

.shadowed {
  box-shadow: 0px 0px 3px $tan-dark;
}

.text-shadowed {
  text-shadow: 0px 0px 0.7em black;
}

.hp-alert {
  font-size: 17px;
  line-height: 1.1;

  &.hp-alert-large {
    font-size: 20px;

    * {
      font-size: 20px;
    }
  }

  &.hp-wholesale-alert {
    margin-top: 20px;
  }

  * {
    font-size: 17px;
    line-height: 1.1;
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
  //justify-content: center;

  .hp-card-col {
    width: 33%;

    @media screen and (max-width: $lg) {
      width: 33%;
    }

    @media screen and (max-width: $md) {
      width: 50%;
    }

    @media screen and (max-width: $xs) {
      width: 100%;
    }
  }
}

.btn,
button.btn {
  font-size: 19px;
  @media screen and (max-width: $lg) {
    font-size: 17px;
  }
  @media screen and (max-width: $sm) {
    font-size: 15px;
  }

  &.btn-lg {
    font-size: 27px;

    @media screen and (max-width: $md) {
      font-size: 24px;
    }
  }

  &.btn-sm {
    padding: 3px 8px !important;
    font-size: 15px !important;
    letter-spacing: 0.005em;
  }

  &.btn-white {
    background: white;
    color: $dark;

    &:hover {
      background: darken(white, 5%);
    }
  }

  &.btn-brand {
    background: $brand;
    color: white;

    &:hover {
      background: darken($brand, 5%);
    }
  }

  &.btn-brand-secondary {
    background: $brand-secondary;
    color: white;

    &:hover {
      background: darken($brand-secondary, 5%);
    }
  }

  &.btn-brand-tertiary {
    background: $brand-tertiary;
    color: white;

    &:hover {
      background: darken($brand, 5%);
    }
  }

  &.btn-green {
    background: $brand;
    color: white;

    &:hover {
      background: darken($brand-tertiary, 5%);
    }
  }

  &.btn-orange {
    background: $brand-secondary;
    color: white;

    &:hover {
      background: darken($brand-secondary, 5%);
    }
  }

  &.btn-blue {
    background: $brand-tertiary;
    color: white;

    &:hover {
      background: darken($brand-tertiary, 5%);
    }
  }

  &.btn-interactive {
    background: $interactive;
    color: white;

    &:hover {
      background: darken($interactive, 5%);
    }
  }
}

.font-inter {
  font-family: Inter, sans-serif !important;
  font-size: 20px;
  font-weight: 600;

  .cm_headerlinks .navbar-nav {
    font-weight: 500;
  }

  h4 {
    font-family: baloo, sans-serif;
    font-size: 36px;
    font-weight: normal;
    color: #47525e;
  }

  h5 {
    font-family: Inter, sans-serif;
    font-size: 30px;
  }

  .h5 {
    font-size: 19px;
  }

  h6 {
    font-size: 18px;
  }

  .two_col_content h3 {
    font-family: Inter, sans-serif;
  }

  .banner-bg h1 {
    font-family: Inter, sans-serif;
  }
}
