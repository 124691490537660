.signin_as {
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
}

#pick-up-host {
  background: #f5f6fa;
}

#customer-orders {
  background: #f5f6fa;
}

.customer-orders-block {
  margin-bottom: 3em;

  .subscription-cancel-button:disabled {
    display: none;
  }

  .table td {
    border-color: $light;
    font-size: 17px;

    @media screen and (max-width: $sm) {
      font-size: 14px;
    }
  }

  .th-product {
    padding-left: 0;
  }

  .td-product-image {
    width: 60px;
    padding: 10px 0 0 10px;

    @media screen and (max-width: $md) {
      width: 50px;
    }

    @media screen and (max-width: $xs) {
      width: 40px;
    }
  }

  .order-table-product-image {
    width: 60px;
    height: 60px;
    border-radius: 2px;
    margin-left: -10px;
    object-fit: cover;

    @media screen and (max-width: $md) {
      width: 50px;
      height: 50px;
    }

    @media screen and (max-width: $xs) {
      width: 40px;
      height: 40px;
    }
  }

  .product-name {
    font-size: 20px;
    line-height: 1.1;
    max-width: 12em;
    margin-left: -10px;

    @media screen and (max-width: $sm) {
      font-size: 16px;
    }
  }

  .order-date {
    line-height: 1.1;
    margin-left: -10px;

    time {
      font-size: 14px;
      display: inline-block;

      @media screen and (max-width: $sm) {
        font-size: 11px;
        line-height: 1.2;
      }
    }
  }

  .distribution-type {
    @extend .label;
  }

  .location {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 300;
    max-width: 15em;
    min-width: 10em;

    @media screen and (max-width: $sm) {
      font-size: 11px;
      line-height: 1.1;
    }
  }

  .product-quantity {
    font-weight: 400;
  }

  .product-frequency {
    font-weight: 400;
  }

  .product-price {
    font-weight: 400;
  }

  .product-status {
    font-weight: 400;
  }
}
