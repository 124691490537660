.alert.cm_header_top {
  border-radius: 0;
}

.header_wrapper {
  .nav-link.cart {
    @media screen and (max-width: $xs) {
      padding-left: 5px;
    }

    img {
      @media screen and (max-width: $lg) {
        max-width: none;
        width: 28px;
      }

      @media screen and (max-width: $xs) {
        width: 25px;
      }
    }

    sup {
      &.cart-empty {
        display: none;
      }

      width: 18px;
      height: 18px;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      padding: 1px 0;
      background: darken($brand, 10%);
      border-radius: 20px;
      color: #fff;
      line-height: 16px;
      display: inline-block;
      top: -12px;
      left: -3px;

      @media screen and (max-width: $lg) {
        width: 18px;
        height: 18px;
        font-size: 12px;
        padding-top: 1px;
        top: -9px;
        left: -5px;
      }

      @media screen and (max-width: $sm) {
        padding-top: 1px;
      }

      @media screen and (max-width: $xs) {
        width: 15px;
        height: 15px;
        font-size: 10px;
        padding-top: 0px;
        top: -9px;
        left: -5px;
      }
    }
  }

  .cm-cart-mobile {
    margin-left: 20px;
  }
}

.scroll-to-top {
  display: none;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 30px;
    height: 30px;
    background: $brand-tertiary;
    border-radius: 3px;

    &:before {
      font-size: 40px;
    }
  }
}

.is-scrolled-past {
  .nav-link.cart {
    position: fixed;
    z-index: 1049;
    right: 30px;
    margin-left: 0;
    top: 40px;

    @media screen and (max-width: $lg) {
      right: 0px;
    }

    @media screen and (max-width: $sm) {
      top: 20px;
    }

    @media screen and (max-width: $xs) {
      right: -10px;
      top: 20px;
    }
  }

  .scroll-to-top {
    @media screen and (max-width: $sm) {
      display: block;
      position: fixed;
      bottom: 40px;
      right: 30px;
      z-index: 1049;
      right: 10px;
      bottom: 50px;
    }
  }
}
