/* Backgrounds */

//background colors

.bg-white {
  background: #ffffff;
}

.bg-ultra-light {
  background: $ultra-light;
}

.bg-light {
  background: $light;
}

.bg-medium {
  background: $medium;
}

.bg-dark {
  background: $dark;
}

.bg-brand {
  background: $brand;
}

.bg-brand-secondary {
  background: $brand-secondary;
}

.bg-brand-tertiary {
  background: $brand-tertiary;
}

.bg-orange {
  background: $brand-orange;
}

.bg-blue {
  background: $brand-blue;
}

.bg-tan {
  background: $tan;
}

//background layouts

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.block-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-position: center center;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all, 0.25s;
  }

  &.mask-dark {
    &:after {
      background: transparentize(black, 0.7);
    }
  }

  &.mask-light {
    &:after {
      background: transparentize(white, 0.6);
    }
  }
}

.block-background-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.background-content {
  position: relative;
  z-index: 1;
}
