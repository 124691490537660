.hp-card-col {
  flex: 0 0 auto;

  &.hp-card-col-product {
    width: 33%;

    @media screen and (max-width: $xl) {
      width: 33%;
    }

    @media screen and (max-width: $md) {
      width: 50%;
    }

    @media screen and (max-width: $xs) {
      width: 100%;
    }
  }

  &.slide {
    padding: 20px 20px 40px 20px;
  }
}

.slick-slider {
  .hp-card-col {
    height: 100%;

    &.hp-card-col-product {
      width: 100% !important;
    }
  }
}

.hp-card {
  border: 1px solid $tan-dark;
  border-radius: 6px;
  box-shadow: 0px 0px 3px 0px $tan-dark;
  position: relative;
  height: 100%;
  transition: box-shadow 0.2s;
  background-size: cover;

  &:hover {
    box-shadow: 0px 0px 10px 0px darken($tan-dark, 3%);

    .hp-card-link {
      transform: scale(1.05);

      &.floating {
        transform: translate(-50%, 50%) scale(1.05);
      }
    }
  }

  .hp-card-background {
    background: $tan;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 6px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .hp-card-icon {
    max-width: 120px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    display: block;
    border-radius: 150px;
    margin: 0 auto 10px auto;

    @media screen and (max-width: $md) {
      max-width: 70px;
    }
  }

  .hp-card-header {
    padding: 20px 10px;
    border-radius: 6px 6px 0px 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hp-card-heading-group {
    height: 80px;
  }

  .hp-card-heading {
    font-size: 26px;
    line-height: 1.1;
    margin-bottom: 5px;
    width: 95%;
    max-height: 52px;
    overflow: hidden;

    @media screen and (max-width: $xl) {
      font-size: 24px;
    }

    @media screen and (max-width: $md) {
      font-size: 21px;
    }
  }

  .hp-card-subheading {
    @extend .font-main;
    font-size: 18px;
    line-height: 1.1;
    //height: 17px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 0;
    //display: inline-block;
    //outline: 1px solid $medium;

    @media screen and (max-width: $sm) {
      font-size: 13px;
    }
  }

  .hp-card-text-item {
    @extend .font-main;
    font-size: 16px;
  }

  .hp-card-label {
    display: block;
    @extend .uppercase;
    font-weight: 500;
    @extend .tracked-less;
    font-size: 14px;
    color: $medium-dark;
    margin-bottom: 0.4em;
  }

  .hp-card-text {
    display: block;
  }

  .hp-card-description {
    font-size: 14px;
    @extend .font-main;
  }

  .hp-card-text-sub {
    display: block;
  }

  .hp-card-price {
    @extend .font-main;
    font-size: 19px;
    color: $interactive;
    font-weight: 600;
  }

  .hp-card-link {
    display: block;
    text-align: center;
    width: 100%;
    transition: transform 0.2s;
    line-height: 1.1;

    &.floating {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 50%);
    }
    a,
    button {
      border: none;
      background: $brand-tertiary;
      color: white;
      font-size: 24px;
      padding: 7px 35px;
      border-radius: 3px;
    }
  }

  .hp-card-body {
    padding: 20px 10px 30px 10px;
  }

  &.hp-card-pickup {
    .hp-card-background {
      //background-color: $brand;
    }

    .hp-card-header {
      background-color: $brand;
    }
    .hp-card-heading,
    .hp-card-subheading {
      color: $tan;
      @extend .centered;
    }

    .hp-card-heading {
      padding: 0 20px;
    }
  }

  &.hp-card-farm {
    &:hover {
      box-shadow: 0px 0px 10px 0px darken($medium, 3%);
    }
    .hp-card-background {
      &:before {
        content: "";
        display: block;
        @extend .background;
        z-index: 0;
        background: rgba(0, 0, 0, 0.25);
      }
    }

    .hp-card-header {
      padding: 20px 10px 0px 10px;
    }

    .hp-card-heading,
    .hp-card-subheading {
      @extend .centered;
      color: white;
      @extend .text-shadowed;
      overflow: visible;
    }

    .hp-card-description {
      @extend .centered;
      color: white;
      font-weight: 500;
      font-size: 17px;
      text-shadow: 0px 0px 0.7em $dark;
    }

    .hp-card-link {
      margin-top: 0.5em;
    }
  }

  &.hp-card-product {
    .hp-card-header {
      aspect-ratio: 4/4;

      @media screen and (max-width: $md) {
        aspect-ratio: 4/3;
      }
    }

    .hp-card-background {
      background: lighten($brand-tan, 1%);
    }

    .hp-card-heading-group {
      height: 95px;
      //border-bottom: 1px solid $light;
    }

    .hp-card-heading {
      max-height: 52px;
    }

    .hp-card-body-details {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .product-details-label {
      font-weight: 400;
      font-size: 17px;
      color: $medium-dark;
      margin-bottom: 4px;

      @media screen and (max-width: $md) {
        font-size: 12px;
      }
    }

    .hp-card-body-details {
      //padding-bottom: 20px;
      //border-bottom: 1px solid $light;
    }

    .hp-card-product-details-row {
      min-height: 40px;
    }

    .product-details-price {
      line-height: 1.1;
      font-size: 19px;
      color: $brand-secondary;
      margin-bottom: 0;
      //margin-top: 10px;

      @media screen and (max-width: $lg) {
        font-size: 17px;
        //margin-top: 20px;
      }
    }

    .subscription-info {
      margin-top: 20px;
      @extend .label;

      span {
        text-transform: none;
        font-size: 17px;
      }
    }

    .hp-card-product-out-of-stock {
      @extend .font-main;
      font-size: 19px;
      margin-top: 20px;
      //text-align: center;
      @extend .medium-dark;
    }
  }
}
