#cart {
  background: #f5f6fa;

  .alert {
    font-size: 19px;
    line-height: 1.2;
    font-weight: 400;
    margin: 20px auto;

    @media screen and (max-width: $sm) {
      font-size: 16px;
    }
  }

  .table td {
    border-color: darken($light, 5%);
  }

  .distribution-instructions {
    //font-size: 18px;
    font-weight: 400;
    color: darken($medium, 10%);
    margin-top: 10px;
  }

  .available-zip-codes {
    font-weight: 400;
  }

  .form-check {
    //line-height: 1.15;
    margin-bottom: 1em;
    max-width: 800px;
    letter-spacing: 0.005em;
    font-weight: 500;
    align-items: center;
    margin-top: 1em;

    input.form-check-input {
      margin-top: 0;
    }

    label {
      line-height: 1.15;
    }
  }

  .deliver-to {
    color: darken($medium, 10%);
    font-weight: 400;
  }

  .deliver-to-address {
    color: $dark;
  }

  .shopping_cart_delivery_note p {
    color: $dark;
  }
}

.cart-block {
  padding: 30px 20px 30px 20px;
  margin-top: 3em;
  border: 1px solid $tan-dark;
  background: white;
  border-radius: 6px;

  @media screen and (max-width: $sm) {
    padding: 20px 10px 20px 10px;
  }

  .table tbody tr {
    &:last-child {
      border-bottom: 1px solid darken($light, 5%);
    }
  }
  tr.error-row td {
    border-top: 0;
    max-width: none;
    padding: 0;
  }

  .nav-tabs {
    margin-top: 30px;

    @media screen and (max-width: $md) {
      margin-top: 0;
    }
  }
}

.cart-block-heading {
  font-size: 36px;

  @media screen and (max-width: $md) {
    font-size: 27px;
  }

  @media screen and (max-width: $sm) {
    font-size: 21px;
  }
}

.total-summary {
  background: white;
  width: 50%;
  margin-left: 50%;
  padding: 20px;

  @media screen and (max-width: $md) {
    margin-left: 0;
    width: 100%;
  }

  h6 {
    //color: darken($medium, 10%);
    font-weight: 400;
  }
}

.total-summary-top {
  margin-bottom: 2em;
  padding-right: 10px;
}

#checkout {
  background: #f5f6fa;

  .container {
    @media screen and (max-width: $md) {
      max-width: none;
    }
  }

  .shopping_list {
    @media screen and (max-width: $xs) {
      //display: block;

      .checkout-product-image {
        margin-bottom: 10px;
      }

      .content {
        .top-heading {
          //display: block;
        }
      }
    }
  }
}

// .ordersummary-item-products{
//   @media screen and (max-width: $sm) {
//     overflow: scroll;
//   }
// }

.ordersummary_item {
  .ordersummary-item-group {
    @extend .boxed;
    margin-bottom: 2em;
  }

  .ordersummary-item-row {
    border-top: 1px solid $light;
    padding-bottom: 10px;
    padding-top: 10px;

    &:last-child {
      border-bottom: 1px solid $light;
    }
  }

  .farmSectionHeading {
    color: $dark;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .shopping_list {
    .product-price {
      font-weight: 300;
      //color: $dark !important;
    }
  }

  .checkout-product-image {
    width: 60px;
    height: 50px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
  }

  .product-name {
    //font-size: 20px !important;
    line-height: 1.1;
    max-width: 10em;
    margin-bottom: 15px !important;

    @media screen and (max-width: $md) {
      font-size: 15px !important;
    }
  }

  .content {
    padding-left: 10px;

    .top-heading {
      .checkout-quantity {
        padding: 0px 8px;
        font-size: 16px;
        border-color: $medium-dark;
        margin: -1px 15px 0 15px;
      }
    }
  }

  .subscription-info,
  .subscription-info-details {
    margin-bottom: 0;
  }

  .checkout-remove-button {
    //font-size: 14px;
    //padding: 2px 8px;
  }

  .location {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 300;
    color: $dark;
    margin-top: 1em;

    @media screen and (max-width: $sm) {
      font-size: 16px;
      line-height: 1.15;
    }
  }

  .checkout-total {
    margin-bottom: 0;

    font-size: 24px;
  }
}

.checkout-button {
  @media screen and (max-width: $sm) {
    margin-bottom: 20px;
  }
}
