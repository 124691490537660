.farmers_cardsection {
  .farmer_card {
    /* background: url(./assets/img/farmerBg.png); */
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    min-height: 350px;

    @media screen and (max-width: $lg) {
      padding: 10px;
    }

    .caption {
      position: relative;
      z-index: 1;
    }

    .farmcard-heading {
      height: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 20px 0;
      //border: 1px solid red;

      @media screen and (max-width: $lg) {
        height: 55px;
      }

      h4 {
        line-height: 1.2;
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 32px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @media screen and (max-width: $lg) {
          font-size: 24px;
          -webkit-line-clamp: 2;
        }
      }
    }

    .farmcard-location {
      height: 50px;
      margin-bottom: 20px;
      //border: 1px solid $brand;

      h5 {
        color: white;
        font-size: 20px;
        line-height: 1.2;

        @media screen and (max-width: $lg) {
          font-size: 16px;
        }
      }
    }

    .farmcard-content {
      //display: none !important;
      height: 120px;
      //height: auto !important;
      overflow: hidden;
      //border: 1px solid red;

      @media screen and (max-width: $lg) {
        height: 80px;
      }

      p {
        height: auto !important;
        font-size: 17px;
        line-height: 1.4;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;

        @media screen and (max-width: $lg) {
          font-size: 14px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }

    .farmer_card_img_wrapper img {
      max-width: 180px;
      border-radius: 50%;
      height: 180px;
      width: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000;
      top: 0;
      left: 0;
      opacity: 0.35;
      border-radius: 10px;
    }
  }

  .farmers_cardsection .farmer_card {
    cursor: pointer;
  }
  .farmers_cardsection.market_popup .farmer_card {
    /* background-image: url(./assets/img/marketplace.png); */
  }

  .farmers_search.header {
    padding: 20px 0;
  }

  .farmer_card_img_wrapper {
    height: 180px;
  }

  .two_col_contentimg .farmer_card {
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .two_col_content {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
}
