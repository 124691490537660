html {
  scroll-behavior: smooth;
}

.home {
  .scroll-to-top {
    display: none !important;
  }
}

#home-introduction {
  background: $dark;

  .slick-list,
  .slick-track,
  .slick-slider {
    height: 100%;
  }
}

.home-introduction-overview {
  font-size: 60px;
  line-height: 1.1;

  @media screen and (max-width: $md) {
    font-size: 40px;
  }

  @media screen and (max-width: $sm) {
    font-size: 30px;
  }
}

.home-title {
  margin-bottom: 0;
  font-size: 90px;

  @media screen and (max-width: $md) {
    font-size: 60px;
  }

  @media screen and (max-width: $xs) {
    font-size: 40px;
  }
}

.slick-home-introduction {
  .slidebox {
    height: 100%;
  }

  img {
    display: block;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.home-tagline {
  font-size: 30px;

  @media screen and (max-width: $sm) {
    font-size: 21px;
  }
}

.how-it-works-row {
  justify-content: center;
}

.how-it-works-card {
  padding: 0 10px;

  @media screen and (max-width: $md) {
    margin-bottom: 4em;

    &.how-it-works-card-distribution {
      margin-bottom: 0;
    }
  }
}

.how-it-works-card-image {
  display: block;
  margin: 0 auto;
  max-width: 300px;
}

.how-it-works-card-heading {
  @media screen and (max-width: $sm) {
    margin-bottom: 0.5em;
  }
}

.how-it-works-card-subheading {
  height: 3.8em;

  @media screen and (max-width: $sm) {
    height: auto;
    margin-bottom: 0.5em;
  }
}

.multi-column-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 0 100%;
}

.multi-column-block {
  width: 50%;

  @media screen and (max-width: $md) {
    width: 100%;
  }

  .multi-column-block-inner {
    height: 100%;
    position: relative;

    @media screen and (max-width: $md) {
      height: auto;
    }
  }
}

.multi-column-block-text {
  .multi-column-block-inner {
    padding: 7vh 5vh;

    @media screen and (max-width: $md) {
      padding: 10vh 5vh;
    }

    @media screen and (max-width: $md) {
      padding: 50px 20px;
    }
  }
}

.multi-column-block-image {
  img {
    width: 100%;
    height: 50vw;
    object-fit: cover;

    @media screen and (max-width: $md) {
      height: 100vw;
    }
  }
}

.keep-up-to-date-1 {
  @media screen and (max-width: $md) {
    order: 2;
  }
}

.newsletter-form {
  input {
    background: white;
    color: $dark;
  }
}

.newsletter-form-top {
  margin-bottom: 20px;

  input {
    width: 100%;
  }
}

.newsletter-form-name {
  display: inline-block;
  width: 48%;
  margin-right: 4%;
}

.newsletter-form-zip {
  display: inline-block;
  width: 48%;
}

.newsletter-form-email {
  margin-bottom: 35px;
}

.container-relative {
  position: relative;
}

.top-right-button {
  position: absolute;
  top: 100px;
  right: 50px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.top-right-button img {
  max-width: 300px;
  height: auto;
  border-radius: 10px; /* Add rounded edges */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .top-right-button {
    top: 10px;
    right: 10px;
  }

  .top-right-button img {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .top-right-button {
    top: 5px;
    right: 5px;
  }

  .top-right-button img {
    max-width: 150px;
  }
}

.text-center {
  text-align: center;
}

.farmers-post-wrapper {
  margin-top: 20px;
}

.custom-image-size {
  width: 90%; /* Increase the width by 10% */
  height: auto; /* Maintain aspect ratio */
  margin-left: 20px;
}
