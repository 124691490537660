/* Helpers and miscellaneous reusable styles */

.jank {
  //border: 3px solid red;
}

.debug {
  outline: 1px solid black;
}

.block {
  position: relative;
  width: 100%;
  display: block;
  //z-index: 1;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
}

.flex-center-horizontal {
  @extend .d-flex !optional;
  @extend .justify-content-center !optional;
}

.overflow-hidden {
  overflow: hidden;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.hidden,
.display-none {
  display: none !important;
}

.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.psuedo {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: $dark;
}

//style the unloaded and loaded states of the page
.loading {
  opacity: 0;
  transition: opacity 0.2s;
}

.loaded {
  opacity: 1;
}

#viewport-label {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 130px;
  height: 40px;
  padding-top: 10px;
  text-align: center;
  color: white;
  z-index: 9999;
  font-size: 14px;
  border-radius: 2px;
  font-family: "Input Sans", mono !important;
  font-weight: bold;
  background: #111;
  border: 1px solid #555;

  * {
    font-family: "Input Sans", mono !important;
    font-weight: bold;
  }

  @media screen and (max-width: $lg) {
    background: #555;
  }

  @media screen and (max-width: $md) {
    background: #999;
  }

  @media screen and (max-width: $sm) {
    background: #ccc;
  }

  @media screen and (max-width: $xs) {
    background: white;
    color: #333;
  }

  #viewport-label-size:before {
    font-size: 17px;
    line-height: 1;
    content: "xl";
    margin-right: 10px;

    @media screen and (max-width: $lg) {
      content: "lg";
    }

    @media screen and (max-width: $md) {
      content: "md";
    }

    @media screen and (max-width: $sm) {
      content: "sm";
    }

    @media screen and (max-width: $xs) {
      content: "xs";
      color: #333;
    }
  }
}

//visualize container-fluid extents
// body:after{
// 	content: '';
// 	width: 3px;
// 	padding: 0px;
// 	background: transparentize(yellow,.5);
// 	position: fixed;
// 	top: 0;
// 	z-index: 9999;
// 	right: $container-fluid-padding;
// 	height: 100vh;
// }

// body:before{
// 	content: '';
// 	width: 3px;
// 	padding: 0;
// 	background: transparentize(yellow,.5);
// 	position: fixed;
// 	top: 0;
// 	z-index: 9999;
// 	left: $container-fluid-padding;
// 	height: 100vh;
// }
