#order-now-nav {
  //margin-bottom: 1em;
  //align-items: end;

  @media screen and (max-width: $sm) {
    //margin-top: 30px;
  }
}

.order-now-nav-type-col {
  @media screen and (max-width: $md) {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
  }
}

.order-now-nav-location-col {
  @media screen and (max-width: $md) {
    padding-bottom: 20px;
    border-bottom: 1px solid $light;
    margin-bottom: 20px;
  }
}

.order-now-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.active {
    margin-top: 20px;
  }

  p {
    margin: 0 10px 0 0;
  }

  @media screen and (max-width: $md) {
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 6px 0;
    }
  }
}

.order-now-additional-products {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.order-now-nav-button {
  height: 40px;
  background: $brand-tan;
  border: 1px solid $tan-dark;
  padding: 4px 25px;
  color: $medium-dark;
  font-size: 19px;
  margin-left: -1px;
  transition: background 0.2s;

  &:hover {
    background: darken($brand-tan, 3%);
  }

  @media screen and (max-width: $xs) {
    font-size: 16px;
    padding: 3px 19px;
  }

  &.active {
    background: $interactive;
    color: white;
    border: 1px solid $tan-dark;
    box-shadow: 0px 0px 4px $tan-dark;

    &:hover {
      cursor: default;
    }
  }

  &:first-child {
    border-radius: 6px 0px 0px 6px;
    margin-left: 0;
  }

  &:last-child {
    border-radius: 0px 6px 6px 0px;
  }
}

.order-now-nav-dropdown.show > .btn-primary.dropdown-toggle {
  background-color: $interactive;
  border-color: $tan-dark;
  color: white;
}

.order-now-nav-dropdown .dropdown-toggle {
  height: 40px;
  background: $brand-tan;
  border: 1px solid $tan-dark;
  padding: 4px 25px;
  color: $medium-dark;
  font-size: 19px;
  margin-left: -1px;
  transition: background 0.2s;

  &:hover {
    background: darken($brand-tan, 3%);
    background-color: $interactive;
    border-color: $tan-dark;
    color: white;
  }

  &:active {
    background-color: $interactive;
    border-color: $tan-dark;
    color: white;
  }

  &:focus {
    background-color: $interactive !important;
    border-color: $tan-dark !important;
    color: white !important;
  }

  @media screen and (max-width: $xs) {
    font-size: 16px;
    padding: 3px 19px;
  }
}

.order-now-nav-type-col {
  //display: flex;
  //justify-content: end;
  //border: 1px solid $light;

  @media screen and (max-width: $lg) {
    //justify-content: start;
  }
}

.order-now-nav-location-col {
  //display: flex;
  //justify-content: end;
  //border: 1px solid $light;

  @media screen and (max-width: $lg) {
    //justify-content: start;
  }
}

// html body .loader_wrapper{
//   position: fixed !important;
//   width: 100% !important;
//   height: 100% !important;
//   z-index: 9999 !important;
//   background: yellow !important;
// }

.order-now-nav-filter-summary-container {
  text-align: right;
  margin-bottom: 3px;

  @media screen and (max-width: $md) {
    text-align: left;
  }
}

.filter-summary {
  font-weight: 300;
  font-size: 21px;
  margin: 0;
  @extend .font-main;
  padding: 7px 10px 12px 10px;
  //position: relative;
  //right: -16px;
  border-radius: 4px;
  border: 1px solid transparent;
  text-transform: none;
  color: $dark;
  height: 40px;
  display: inline-block;
  margin-bottom: 5px;

  &.issue {
    //color: $brand-secondary;

    * {
      //color: $brand-secondary;
    }
  }

  &.interactive {
    &:hover {
      cursor: pointer;
      background: $brand-tan;
      border: 1px solid $tan-dark;

      &:after {
      }
    }
  }

  &.show-caret {
    &:after {
      display: inline;
    }
  }

  &.toggled {
    &:after {
      content: "€";
    }
  }

  @media screen and (max-width: $sm) {
    font-size: 18px;
    padding: 8px 10px 8px 6px;
    //margin-left: -2px;
  }

  &:after {
    content: "ﬁ";
    font-family: "modern_pictograms_proregular";
    font-size: 24px;
    line-height: 100%;
    vertical-align: middle;
    position: relative;
    display: none;
    margin-left: 4px;
    //opacity: 0;
  }
}

.filter-clear {
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "modern_pictograms_proregular";
  font-size: 24px;
  line-height: 100%;
  vertical-align: middle;
  position: relative;
  margin: 0;
  margin-left: 12px;
  background: none;
  padding: 0;
}

.filter-summary-data {
  color: $interactive;
}

.order-now-nav-location-bottom {
  display: flex;
  justify-content: end;
  opacity: 0;
  //margin-top: -10px;

  &.active {
    opacity: 1;

    @media screen and (max-width: $md) {
      display: flex;
    }
  }

  @media screen and (max-width: $md) {
    justify-content: start;
    opacity: 1;
    display: none;
  }
}

#order-now-zip-input {
  display: inline;
  background: lighten($tan, 0%);
  border: 1px solid lighten($medium, 10%);
  border-radius: 8px;
  width: 355px;
  height: 35px;
  margin-top: 1px;
  margin-bottom: 0 !important;
  color: $medium-dark;
  box-shadow: inset 0px 0px 6px transparentize($medium-dark, 0.5);
  //text-align: center;

  @media screen and (max-width: $sm) {
    height: 35px;
    width: 100%;
  }

  &:focus {
    border: 2px solid $interactive;
    color: $dark;
  }
}

.order-now-nav-location-errors {
  display: flex;
  justify-content: end;
}
.order-now-error {
  // max-width: 365px;
  margin-top: 15px;
}

.order-now-zip-go {
  display: inline;
  border: 1px solid $tan-dark;
  background: $interactive;
  color: white;
  border-radius: 8px;
  padding: 0px 12px;
  margin-left: 15px;
  font-size: 19px;

  &:hover {
    background: darken($interactive, 3%);
  }
}

.order-now-delivery-button {
  display: inline;
  border: 1px solid $tan-dark;
  background: $interactive;
  color: white;
  border-radius: 8px;
  padding: 0px 12px;
  margin-left: 15px;
  font-size: 19px;

  &:hover {
    background: darken($interactive, 3%);
  }

  &:disabled {
    background: $brand-tan;
    border: 1px solid $tan-dark;
    color: $medium-dark;
  }
}

.order-now-main {
  min-height: 400px;
}

.order-now-distribution {
  // visibility: hidden;
  // height: 0;
  // margin: 0;
  // padding: 0;
  position: relative;
  padding-top: 20px;

  @media screen and (max-width: $sm) {
    padding-top: 0;
  }

  // &.mask-content {
  //   .order-now-slider-container {
  //     opacity: 0.2;

  //     &:after {
  //       @extend .background;
  //       width: 200vw;
  //       left: -$container-fluid-padding;
  //       content: "";
  //       display: block;
  //       z-index: 3;
  //     }
  //   }
  // }

  &.active {
    height: auto;
    visibility: visible;
  }
}

.order-now-distribution-alert {
  //margin-top: 3em;
  //margin-bottom: 2em;
  margin: 0px auto 0px auto;
  //max-width: 700px;

  &.alert-info {
    background: lighten($brand-tertiary, 30%);
    border: 1px solid lighten($brand-tertiary, 20%);
  }
}

.order-now-no-results {
  margin-top: 40px;
  margin-bottom: 40px;
}

.order-now-slider-container {
  margin-left: -20px;
  margin-right: -20px;
}

.slick-order-now {
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    & > div {
      height: 100%;
    }
  }

  .slick-dots {
    li button:before {
      font-size: 9px;
    }
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    right: -50px;
    z-index: 1;

    @media screen and (max-width: $lg) {
      right: -37px;
    }

    @media screen and (max-width: $sm) {
      right: 9px;
    }

    &:before {
      content: "—";
      font-family: "modern_pictograms_proregular";
      font-size: 40px;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      display: block;
      padding: 0px;
      width: 40px;
      height: 40px;
      background: white;
      opacity: 1;
      border-radius: 4px;
      border: 1px solid $tan-dark;
      box-shadow: 0 0 4px $tan-dark;
      color: $dark;
    }

    &.slick-prev {
      left: -50px;
      @media screen and (max-width: $lg) {
        left: -34px;
      }
      @media screen and (max-width: $sm) {
        left: 9px;
      }
      &:before {
        content: "‘";
      }
    }
  }
}
