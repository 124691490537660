.product_card,
.modal {
  .product-name {
    margin-bottom: 2px;
    font-size: 23px;
    @extend .font-secondary;

    @media screen and (max-width: $lg) {
      font-size: 18px;
    }

    @media screen and (max-width: $sm) {
      font-size: 15px;
    }
  }
  .product-variety {
    font-size: 17px;
    margin-top: 3px;
    color: $medium-dark;
    margin-bottom: 15px;

    @media screen and (max-width: $lg) {
      font-size: 15px;
    }

    @media screen and (max-width: $sm) {
      font-size: 12px;
    }
  }
}

.product_card_wrapper {
  padding: 0 20px;
  //width: 25%;
}

.product_card {
  position: relative;
  height: 500px;

  @media screen and (max-width: $lg) {
    height: 450px;
  }

  @media screen and (max-width: $md) {
    height: 400px;
  }

  @media screen and (max-width: $sm) {
    height: 370px;
  }

  &.boxed {
    background: lighten($brand-tan, 1%);
    max-width: none;
    box-shadow: 0px 0px 3px 0px $tan-dark;

    .product_img {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-top: -20px;
      border-radius: 6px 6px 0px 0px;
      overflow: hidden;

      @media screen and (max-width: $sm) {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-top: -10px;
      }
    }

    .btn-info {
      position: absolute;
      bottom: -15px;
      left: 10%;
      width: 80%;
    }
  }

  .product_img {
    overflow: hidden;
    height: 220px;

    @media screen and (max-width: $lg) {
      height: 150px;
    }

    @media screen and (max-width: $md) {
      height: 120px;
    }
  }

  .heading-align {
    height: 100px;
    margin-bottom: 10px;

    @media screen and (max-width: $md) {
      height: 80px;
    }
  }

  .cm_product_details {
    flex-wrap: wrap;
    height: 110px;
    margin-bottom: 10px;

    @media screen and (max-width: $md) {
      height: 100px;
    }

    .product-details-label {
      font-weight: 400;
      font-size: 14px;
      color: darken($medium, 10%);

      @media screen and (max-width: $md) {
        font-size: 12px;
      }
    }

    .product-details-price {
      line-height: 1.1;
      margin-top: 15px;
      font-size: 17px;

      @media screen and (max-width: $lg) {
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }

  .btn-info {
    line-height: 1;
    font-size: 22px;
    padding: 10px 10px;

    @media screen and (max-width: $lg) {
      font-size: 17px;
    }

    &:disabled {
      opacity: 1;
      background: lighten($brand-tertiary, 20%) !important;
      border: lighten($brand-tertiary, 20%) !important;
      color: #efefef;
    }
  }
}

.subscription-info {
  width: 100%;
  margin-top: 1em;
  line-height: 1.1;
  color: $medium-dark;
  font-size: 14px;
  font-weight: 400;

  @media screen and (max-width: $lg) {
    margin-top: 0.5em;
  }

  .subscription-info-details {
    display: block;
    margin-top: 3px;
    font-weight: 500;
    font-size: 17px;
    color: $brand-secondary;

    @media screen and (max-width: $lg) {
      font-size: 14px;
    }
  }
}
